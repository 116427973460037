import React from 'react'
import './Style.css'
function DataExtractionContent() {
  return (
    <div className="container">
    <div className='coming-soon'>
      Coming Soon..
    </div>
    </div>
  )
}

export default DataExtractionContent
