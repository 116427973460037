
import React from 'react'
import CaseSearchForm from './CaseSearchForm/CaseSearchForm'


function LegalSearchContent() {

  return (
    <div>
      <CaseSearchForm />
    </div>
  )
}

export default LegalSearchContent
