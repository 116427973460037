import React, { useState } from 'react'

import './CaseSearchForm.css'
// import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import { handleError, handleSuccess } from 'Pages/Utils';
import { ToastContainer } from 'react-toastify';

function CaseSearchForm({ onClose, onFormSubmit }) {

    const [formData, setFormData] = useState({
        caseDetails: '',
        act: ''
      });
    

      const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };

      
      const handleSubmit = async (e) => {
        e.preventDefault();
        
        //API CALL
        try {
          // const url = "http://localhost:8080/legalSearch/add";
          const url ="http://13.126.95.152:8080/legalSearch/add"
          const headers = {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': 'application/json'
        };
        
        const body = JSON.stringify({
          caseDetails: formData.caseDetails,
          act: formData.act,
        });
          // send data to result component response data
          // onFormSubmit(response.data);
          const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: body
        });
        const result = await response.json(); 
    
        if (result.success) {
          handleSuccess(result.message);
          // Clear the form fields
          setFormData({
            caseDetails: '',
            act: ''
          });
          
          setTimeout(() => {
            onFormSubmit(result.doc);
            onClose();
          }, 1000)

         } else {
          handleError(result.message);
        }

        } catch (err) {
           handleError(err);
        }
      }

  return (
    
      <div className="casesearch-form-container">
        <div className="casesearch-form">
          <div className="casesearch-form-heading">
            Legal Research
            <FaTimes onClick={onClose} className="casesearch-close-icon" />
          </div>

          <form id="search-model-form" className="search-model-form" onSubmit={handleSubmit}>
            
            <div className="search-model-input-group">
              <label htmlFor="Case">Write Case Details</label>
              <textarea
                id="Case"
                name="caseDetails"
                placeholder="Case Details..."
                value={formData.caseDetails}
                onChange={handleChange}
                required
              />
            </div>

            <div className="search-model-input-group">
              <label htmlFor="Act">Acts/Subsections</label>
              <textarea
                id="Act"
                name="act"
                placeholder="Acts/Subsections..."
                value={formData.act}
                onChange={handleChange}
                required
              />
            </div>

            {/* <div className="search-model-input-group">
              <label htmlFor="specialConsiderations">Special Considerations and Unique Details</label>
              <textarea
                id="specialConsiderations"
                name="specialConsiderations"
                placeholder="Special Considerations..."
                value={formData.specialConsiderations}
                onChange={handleChange}
              />
            </div>
           */}
            <div className="case-search-submit-button">
              <button className="btn btn-primary case-search-submit-button" type="submit">Submit</button>
            </div>

          </form>
          <ToastContainer />
        
        </div>
      </div>
  )
}

export default CaseSearchForm
