import './Footer.css'
function Footer(){
    
    return(
        <div className="footer-container">
            <div className="footer-section">
                
                <div className="overview">
                    <div className="footer-heading">EFFICENCY</div>
                    <div className="footer-details">Automate workflows, save time, money, research, drafting, verifying</div>
                </div>
                
                <div className="our-details">
                    <div className="footer-heading">SIMPLIFY</div>
                    <div className="footer-details">Contact@Synlex.tech</div>
                </div>

                <div className="synexAI-email">
                    <div className="footer-heading">STREAMLINE</div>
                    <div className="footer-details">Automate workflows for lawyers efficiently.</div>
                    
                </div>

            </div>
            <div className="copyright">© 2024. All rights reserved.</div>
        </div>
    );
}

export default Footer;