import React from 'react'
import './About.css';

function About() {
  return (
    <div className="about-us-container">
      <section className="about-us-intro">
        <h1 className="about-us-title">About Synlex Intelligence</h1>
        <p className="about-us-text">
          At Synlex Intelligence, we are transforming the legal industry through cutting-edge artificial intelligence. Our custom-trained AI models are built to streamline legal research, document analysis, and case management, enabling law firms to operate more efficiently and effectively. By leveraging the latest advancements in AI, we empower legal professionals to focus on what truly matters—delivering justice.
        </p>
      </section>

      <section className="about-us-ai-models">
        <h2 className="about-us-subtitle">Custom-Trained AI Models</h2>
        <p className="about-us-text">
          Our AI solutions are uniquely designed to address the needs of legal professionals, trained on vast datasets of Indian laws, regulations, and case precedents. Whether performing intricate legal research, generating case summaries, or extracting critical information from legal documents, our AI models deliver accurate, context-aware insights. We make complex legal processes simple, helping lawyers enhance their productivity while maintaining precision and reliability.
        </p>
      </section>

      <section className="about-us-data-security">
        <h2 className="about-us-subtitle">Commitment to Data Security and Privacy</h2>
        <p className="about-us-text">
          We understand the critical importance of data security and privacy in the legal profession. At Synlex Intelligence, we implement state-of-the-art encryption, secure data storage, and adhere to the highest standards of privacy compliance. Our platform is built with rigorous safeguards to ensure that all client data is protected and remains confidential. Our commitment to privacy is absolute, reflecting our core values of trust and integrity.
        </p>
      </section>

      <section className="about-us-founder">
        <h2 className="about-us-subtitle">Meet Our Founder</h2>
        <p className="about-us-text">
          Synlex Intelligence was founded by Yatharth Garg, an expert in artificial intelligence. With years of experience building AI-driven solutions, Yatharth has a vision to revolutionize the legal industry by making advanced technology accessible to law firms and legal professionals. His dedication to innovation and efficiency has led Synlex Intelligence to create transformative tools that reshape the way legal work is done, allowing professionals to focus on higher-value tasks while relying on AI for time-consuming processes.
        </p>
      </section>

      <section className="about-us-team">
        <h2 className="about-us-subtitle">Our Team</h2>
        <p className="about-us-text">
          Behind Synlex Intelligence is a diverse team of engineers, AI specialists, legal experts, and data scientists who are passionate about creating intelligent solutions for the legal world. Our team’s combined expertise in technology and law ensures that every product we deliver is meticulously designed to meet the practical needs of law firms while staying ahead of technological trends. We work closely with legal professionals to continuously refine our solutions, ensuring that Synlex Intelligence remains at the forefront of legal AI innovation.
        </p>
      </section>
    </div>

  )
}

export default About;