import image from 'assets/RevolutionAI.jpg'
import './RevolutiionizingAI.css'

function RevolutionizingAI(){
    return(
        <div className="revolutionizingAI-container">
            <div className="AI-heading">
            Revolutionizing legal workflows with AI
            </div>

            <div className="AI-sections">

                <div className="AI-section-grid1">
                    
                    <div className="AI-section-heading">Innovative AI-powered legal automation</div>

                    <div className="AI-section-details">At Synlex AI, we offer a cutting-edge solution that streamlines processes for lawyers. Our platform automates research, drafting, document verification, and case management, saving time and money.</div>

                </div>

                <div className="AI-section-grid2">

                    <div className="AI-section-heading">Efficient solution for legal professionals</div>

                    <div className="AI-section-details">Our all-in-one product simplifies workflows, enhances productivity, and optimizes resources for legal practitioners. Join us in transforming the legal industry with Synlex AI.</div>

                </div>

            </div>
            
            <div className="AI-image-container">
                <img className="AI-image" src={image} alt="AI-image" loading='lazy'/>
            </div>
        
        </div>
    );
}

export default RevolutionizingAI;