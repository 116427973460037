import React, { useState } from 'react';
import './SEResult.css';
import { Link } from 'react-router-dom';


function SEResult({ resultData }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

if (!resultData) {
   return <div>No Data found</div>;
}

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedCard(null);
  };

  
  return (
    <div className="result-component">
      {/* <div className="back-link">
        <i className="fa-solid fa-arrow-left-long"></i>
        <Link to="/demo">back to search</Link>
      </div> */}

      <div className="result-cards">
        {resultData.map((card, index) => (
          <div className='the-card' key={index} onClick={() => handleCardClick(card)}>
            <div className="card-title">{card.party1} <span>VS</span></div>
            <div className="card-title">{card.party2}</div>
            <div className="summury-ss">{card.summary}</div>
            <div className="card-topic">
              <span>Year: {card.year}</span> <span>click for more details</span>
            </div>
          </div>
        ))}
      </div>

      {modalOpen && selectedCard && (
        <div className="modal-overlay" onClick={handleCloseModal}>

          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            
          <div className="model-title">
            <Link 
             to={`https://www.google.com/search?q=${selectedCard.party1}+vs+${selectedCard.party2}`}
             target="_blank"
             rel="noopener noreferrer">
              {selectedCard.party1} 
              &nbsp;&nbsp;
              <span>VS</span> 
              &nbsp;&nbsp;
              {selectedCard.party2} 
              &nbsp;&nbsp;<i className="fa-solid fa-up-right-from-square"></i>
            </Link>
          </div>
            
          <div className='modal-summary'>{selectedCard.summary}</div>

          <div className='modal-card-detail'>
            <span>Year: </span>{selectedCard.year}
          </div>

            <button className="close-button" onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}

    </div>
  );
}

export default SEResult;
