import React from 'react';
import Workflow from './WorkFlow/Workflow';
import Footer from './Footer/Footer';
import RevolutionizingAI from './RevolutionizingAI/RevolutionizingAI';
import Contact from './Contact/Contact';
import HeroSection from './HeroSection/HeroSection';

function index() {
    
    return(
        <>
        <HeroSection />
        <Workflow/>
        <RevolutionizingAI/>
        <Contact/>
        <Footer/>
        </>
    );
  }
  
  export default index;