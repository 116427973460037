import React from 'react'
import video from 'assets/HeroSection.mp4'

import './HeroSection.css'

function HeroSection() {
  return (
    <div className='herosection'>
        <div className="herosection-overlay"></div>
      <video src={video} autoPlay loop muted />
      <div className="herosection-content">
      <h1>Automate Workflows</h1>
      <p>Save time and money on research, drafting, verifying document details with Synlex AI.</p>
      </div>
    </div>
  ) 
}

export default HeroSection
