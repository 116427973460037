import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Demo.css';
import Demo1 from '../../assets/Demo1.jpg';
import Demo2 from '../../assets/Demo2.jpg';


function Demo() {
  const [formData, setFormData] = useState({
    caseDetails: '',
    acts: '',
    specialDetails: ''
  });

  

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    
    // Here you can handle form submission, e.g., send data to an API

    try {
      const response = await axios.post('http://127.0.0.1:5000/submit-form', formData, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      // Navigate to the new page with the response data
      navigate('/result', { state: { cards: response.data } });

    } catch (error) {
      console.error('There was a problem with the Axios request:', error);
    }

    ///////////////////
    
    console.log(formData);
    setFormData({
      caseDetails: '',
      acts: '',
      specialDetails: ''
    });
    console.log(formData);
  };

  return (
    <div className="demo-component">
      <div className="demo-img">
        <img src={Demo1} alt="img1" />
        <img src={Demo2} alt="img2" />
      </div>
      
      <div className="demo-forum-container">
        <div className="demo-forum">
          <div className="demo-form-heading">Synlex Technologies</div>

          <form id="model-form" className="model-form" onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="Case">Write Case Details</label>
              <textarea
                id="Case"
                name="caseDetails"
                placeholder="Case Details..."
                value={formData.caseDetails}
                onChange={handleChange}
                required
              />
            </div>

            <div className="input-group">
              <label htmlFor="Acts">Acts/Subsections</label>
              <textarea
                id="Acts"
                name="acts"
                placeholder="Acts/Subsections..."
                value={formData.acts}
                onChange={handleChange}
                required
              />
            </div>

            <div className="input-group">
              <label htmlFor="specialDetails">Special Considerations and Unique Details</label>
              <textarea
                id="specialDetails"
                name="specialDetails"
                placeholder="Special Details..."
                value={formData.specialDetails}
                onChange={handleChange}
              />
            </div>
          
            <div className="demo-submit-button">
              <button className="btn btn-primary submit-button" type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <br/>
    </div>
  );
}

export default Demo;
