import React, { useEffect, useRef } from 'react';
import card1 from 'assets/workflow1.avif'
import card2 from 'assets/workflow2.avif'
import card3 from 'assets/workflow3.avif'
import './Workflow.css';


function Workflow(){
    const cardsRef = useRef([]); // Initialize as an empty array

    useEffect(() => {
        // Create a local variable to hold the current ref values
        const cards = cardsRef.current;
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                } else {
                    entry.target.classList.remove('animate');
                    void entry.target.offsetWidth; // Trigger a DOM reflow to reset animation
                }
            });
        }, {
            threshold: 0.1, // Trigger when 10% of the component is in view
        });

        // Observe each card
        cards.forEach((card) => {
            if (card) observer.observe(card);
        });

        // Cleanup function to unobserve elements
        return () => {
            cards.forEach((card) => {
                if (card) observer.unobserve(card);
            });
        };
    }, []);

    return(
        <div className="workflow-container">
            
            <div className="workflow-heading">
                Automate Workflows
            </div>
            <div className="workflow-subheading">
                Save time and money on research, drafting, verifying document details and managing case flows.
            </div>
            <div className="workflow-card-container">

                <div className="workflowcard" ref={(el) => (cardsRef.current[0] = el)}>
                    <img src={card1} alt="card1" /><br/>
                    <span className='cardhead'>Time-saving Drafting Tools</span>
                    {/* <div className='GAP'></div> */}
                    <span className='cardsubhead'>Streamline your Document drafting process with our AI-powered solution.</span>
                </div>

                <div className="workflowcard" ref={(el) => (cardsRef.current[1] = el)}>
                    <img src={card2} alt="card1" /><br/>
                    <span className='cardhead'>Document Verification Tool</span>
                    {/* <div className='GAP'></div> */}
                    <span className='cardsubhead'>Ensure accuracy and efficiency in document verification with our advanced AI technology.</span>
                </div>

                <div className="workflowcard" ref={(el) => (cardsRef.current[2] = el)}>
                    <img src={card3} alt="card1" /><br/>
                    <span className='cardhead'>Highly Accurate Legal Research</span>
                    {/* <div className='GAP'></div> */}
                    <span className='cardsubhead'>AI-Powered Legal Research</span>
                </div>
            </div>
        </div>
    );
}
export default Workflow;