import React, { useState } from 'react'
import './SignUP.css'
import { handleError, handleSuccess } from 'Pages/Utils';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';


function SignUP() {
  const [signupInfo, setSignupInfo] = useState({
    name: '',
    email: '',
    password: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const {name, value} = e.target;
    const copysignupInfo = { ...signupInfo };
    copysignupInfo[name] = value;
    setSignupInfo(copysignupInfo);
  }

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // You can now use the username and password state variables
    // console.log(signupInfo);
    // Add logic here to process the signup, like making an API call
    try {
      // const url = "http://localhost:8080/auth/signup" ;
      const url = "http://13.126.95.152:8080/auth/signup";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          'content-type' : 'application/json'
        },
        body: JSON.stringify(signupInfo)
      }) ;
      const result = await response.json();
      const { success, message, error} = result;
      if(success){
        handleSuccess(message);
        setTimeout(() => {
          navigate('/login')
        }, 1000)
      }else if(error){
        const details = error?.details[0].message;
        handleError(details);
      }else if(!success){
        handleError(message);
      }
      // console.log(result);
    } catch (err) {
      handleError(err);
    }

    // navigate('/login')
    //clear the signup form
    setSignupInfo({
      name: '',
      email: '',
      password: ''
    });
    
  };


  return (
    <div className='signup-container'>

      <div className="signup-form">

        <div className="signup-header">
          Signup
        </div>
        
        <form onSubmit={handleSubmit} >
          
          <div className="signup-input-box">
            <input 
              type="text" 
              name= "name"
              value={signupInfo.name}
              placeholder='Username' 
              onChange={handleChange}
              autoFocus
              required
            />
            <i className="fa-solid fa-user signup-page-icon"></i>
          </div>

          <div className="signup-input-box">
            <input 
              type="email" 
              name='email'
              value={signupInfo.email}
              placeholder='email' 
              onChange={handleChange}
              required
            />
            <i className="fa-solid fa-envelope signup-page-icon"></i>
          </div>
          
          <div className="signup-input-box">
            <input 
              type="password" 
              name = "password"
              value={signupInfo.password}
              placeholder='Password'
              onChange={handleChange} 
              required
            />
            <i className="fa-solid fa-lock signup-page-icon"></i>
          </div>
        
          <button type='submit' className='btn btn-primary signup-page-button'>Signup</button>

          <div className="registration-link">
            <p>already have a account?</p>
            <Link to='/login'>Login</Link>
          </div>

        </form>
        <ToastContainer />
      </div>

    </div>

  )
}

export default SignUP
