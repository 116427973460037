import React from 'react'

function DraftingContent() {
  return (
    <div>
      Drafting Content
    </div>
  )
}

export default DraftingContent
