import { Button, Card, Flex, Typography } from 'antd'
import React, { useState } from 'react'
import CaseSearchForm from '../LegalSearch/CaseSearchForm/CaseSearchForm'
import './DashboardContent.css'
import SEResult from '../LegalSearch/ResultData/SEResult'


function MainContent() {

  // Control the visibility of the form
  const [showPrompt, setShowPrompt] = useState(false); 
  const [resultData, setResultData] = useState(null);

  const handleFormSubmit = (data) => {
    console.log('Form data received:', data);
    setResultData(data);
    setShowPrompt(false);
  };


  return (
    <div className='main-content-container' >
      <Flex vertical gap='2.3rem'>
        
        <Card className='main-content-card'>
          <Flex vertical gap='0px'>
              
              <Flex vertical align='flex-start' >
                  <Typography.Title level={2} strong>
                      Create New case
                  </Typography.Title>
              </Flex>

              <Flex gap='large'>
                  <Button type='primary' size='large' onClick={() => setShowPrompt(true)}>Case Search</Button>
                  <Button type='primary' size='large'>Drafting</Button>
              </Flex>

          </Flex>
        </Card>

        {/* Add new content beneath the card */}
        {showPrompt && (
          <div className="case-search-popup">
            <CaseSearchForm onClose={() => setShowPrompt(false)} onFormSubmit={handleFormSubmit}/>             
          </div>
        )}
        
        {resultData && (
          <div className="result-container">
              <SEResult resultData={resultData} />
          </div>
        )}

      </Flex>
    </div>
  )
}

export default MainContent
