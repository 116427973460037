import React, { useRef, useState, useEffect} from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';
function Contact(){
    const form = useRef();
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('success');

  const sendEmail = (e) => {
    e.preventDefault();

    // emailjs.sendForm('service_u3wl45j', 'template_ue7zzcn', form.current, 'YaPyqmuLxogEe1mbw')
    emailjs.sendForm('service_8hm4xnb', 'template_l7cjh2i', form.current, 'tOmZDkLBtctvKOnU1')
      .then((result) => {
        setAlertMessage('Message sent successfully!');
        setAlertType('success');
        setShowAlert(true);
      }, (error) => {
        console.error('EmailJS Error:', error.text); ///error check
        setAlertMessage('Failed to send the message, please try again.');
        setAlertType('danger');
        setShowAlert(true);
      });

      e.target.reset();
  };

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000); // 2 seconds

      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [showAlert]);

  
    return(
        <div id="contact" className="contact-me-container">
            
            <div className="contact-container">

                <div className="contact-heading">Contact US</div>

                <div className="contact-subheading">Connect with us to streamline your legal workflows and save time.</div>

                <div className="contact-forum">
                  
                            <form id="contact-form" ref={form} onSubmit={sendEmail}>
                            
                                    <div className="form-group">
                                        <label htmlFor="name">Enter your name*</label>
                                        <input type="text" id="name" name="name" placeholder="Enter your name" autoComplete="name" required />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email" >Enter your email address*</label>
                                        <input type="email" id="email" name="email" placeholder="Enter your email address" autoComplete="email" required />
                                    </div>
                        
                                    <div className="form-group">
                                        <label htmlFor="message" className="form-control">Leave a message here*</label>
                                        <textarea id="message" name="message" placeholder="Type your message"  required></textarea>
                                    </div>
                                
                                <div className="contact-button-container">
                                    <button className="btn btn-primary contact-button " type="submit">Share Your Information</button>
                                </div>
                                
                            </form>
                        </div>

                <div className={`alert alert-${alertType} alert-dismissible fade show position-fixed`} style={{ top: '20px', right: '20px', zIndex: 10000 }}>
                {alertMessage}
            </div>
            </div>
         </div>
    );
}

export default Contact;