import React, { useEffect, useState } from 'react'
import { Avatar, Flex, Typography } from 'antd'
import Search from 'antd/es/transfer/search'
import { MessageOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons'

function CustomHeader() {
    const [loggedInUser, setLoggedInUser] = useState();
    useEffect(()=>{
      setLoggedInUser(localStorage.getItem('loggedInUser'))
    },[]);
  return (
    <Flex align='center' justify='space-between'>
        <Typography.Title level={2} type='secondary'>
          Welcome Back, {loggedInUser}
        </Typography.Title>
        <Flex align='center' gap='3rem'>
          
          {/* <Search placeholder='Search Dashboard' allowClear enterButton/> */}
          
          <Flex align='center' gap='10px'>
            {/* <MessageOutlined className='db-header-icon'/>
            <NotificationOutlined className='db-header-icon'/> */}
            <Avatar icon={<UserOutlined/>} />         
          </Flex>    
        
        </Flex>
    </Flex>
  )
}

export default CustomHeader
