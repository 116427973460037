import React, { useState } from 'react'

import styles from './CaseSearchForm.module.css'

import { handleError, handleSuccess } from 'Pages/Utils';
import { ToastContainer } from 'react-toastify';
import SEResult from '../../LegalSearch/ResultData/SEResult';

function CaseSearchForm() {

  const [resultData, setResultData] = useState(null);
  const [formVisible, setFormVisible] = useState(true);

    const [formData, setFormData] = useState({
        caseDetails: '',
        act: '',
        specialConsiderations: ''
      });
    

      const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };

      
      const handleSubmit = async (e) => {
        e.preventDefault();
        
        //API CALL
        try {
          // const url = "http://localhost:8080/legalSearch/add";
          const url = "http://13.126.95.152:8080/legalSearch/add";
          const headers = {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': 'application/json'
        };
        const body = JSON.stringify({
          name: formData.name,
          caseDetails: formData.caseDetails,
          act: formData.act,
          specialConsiderations: formData.specialConsiderations
        });
          // send data to result component response data
          // onFormSubmit(response.data);
          const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: body
        });
        const result = await response.json(); 
    
        if (result.success) {
          handleSuccess(result.message);
          // Clear the form fields
          setFormData({
            caseDetails: '',
            act: '',
            specialConsiderations: ''
          });

          setResultData(result.doc);
          setFormVisible(false);
          
          setTimeout(() => {
            ////////////////////////////////
          }, 1000)

         } else {
          handleError(result.message);
        }

        } catch (err) {
           handleError(err);
        }
      }
      const toggleFormVisibility = () => {
        setFormVisible(!formVisible);
      };

  return (
    
    <>
        {formVisible ? (
        <div className={styles.casesearchform}>
          <div className={styles.casesearchformheading}>
            Legal Research
          </div>

          <form className={styles.searchmodelform} onSubmit={handleSubmit}>
            <div className={styles.searchmodelinputgroup}>
              <label htmlFor="caseDetails">Write Case Details</label>
              <textarea
                id="caseDetails"
                name="caseDetails"
                placeholder="Case Details..."
                value={formData.caseDetails}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.searchmodelinputgroup}>
              <label htmlFor="act">Acts/Subsections</label>
              <textarea
                id="act"
                name="act"
                placeholder="Acts/Subsections..."
                value={formData.act}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.searchmodelinputgroup}>
              <label htmlFor="specialConsiderations">Special Considerations and Unique Details</label>
              <textarea
                id="specialConsiderations"
                name="specialConsiderations"
                placeholder="Special Considerations..."
                value={formData.specialConsiderations}
                onChange={handleChange}
              />
            </div>

            <div className={styles.casesearchsubmitbutton}>
              <button className=" btn btn-primary {styles.casesearchsubmitbutton}" type="submit">Submit</button>
            </div>
          </form>
          <ToastContainer />
        </div>
      ) : (
        <div className={styles.toggleButtonContainer}>
          <button className="btn btn-primary {styles.toggleButton}" onClick={toggleFormVisibility}>
            Show Form
          </button>
        </div>
      )}
        {resultData && (
          <div className={styles.resultcontainer}>
              <SEResult resultData={resultData} />
          </div>
        )}

      </>
    
  )
}

export default CaseSearchForm
